import { mapGetters } from 'vuex';

export default {
	data () {
		return {
			editNewsItemModal: {
				active: false,
				newsItem: null
			},
			newNewsItem: {
				title: '',
				description: '',
				also_see: '',
				url: '',
				theme_ids: [],
				news_source_ids: []
			},
			errorMessages: [],
			searchKeyword: '',
			currentPage: 1
		}
	},
	watch: {
		searchKeyword(keyword) {
			if(this.currentPage == 1) {
				this.fetchNewsItems();
			} else {
				this.currentPage = 1;
			}
		},
		currentPage(page) {
			this.fetchNewsItems();
		}
	},
	computed: {
		pages () {
			return Math.ceil(this.pagination.total / this.$store.getters.paginationLimit);
		},
		...mapGetters({
			newsSources: 'newsSource/newsSources',
			themes: 'theme/themes',
			newsItems: ['newsItem/newsItems'],
			pagination: ['newsItem/pagination'],
			isLoadingResources: 'isLoadingResources'
		})
	},
	created () {
		this.$store.dispatch('theme/fetchThemes')
		this.$store.dispatch('newsSource/fetchNewsSources')
		this.fetchNewsItems()
	},
	methods: {
		fetchNewsItems () {
			this.$store.dispatch('newsItem/fetchNewsItems', {page: this.currentPage, q: this.searchKeyword})
		},
		deleteNewsItem (id) {
			this.$store.dispatch('newsItem/deleteNewsItem', id).then(() => {
				this.$notify({
					title: 'Nieuwsbericht verwijderd',
					message: 'Nieuwsbericht is succesvol verwijderd!',
					type: 'success',
					position: 'bottom-right'
				})
			});
		},
		editNewsItem (newsItem) {
			this.editNewsItemModal = {
				active: true,
				newsItem: {
					...newsItem,
					news_source_ids: newsItem.news_sources.map(t => t.id),
					theme_ids: newsItem.themes.map(t => t.id)
				}
			}
		},
		createNewsItem () {
			this.$store.dispatch('newsItem/createNewsItem', this.newNewsItem).then(() => {
				this.errorMessages = []
				this.newNewsItem = {
				title: '',
				description: '',
				also_see: '',
				url: '',
				theme_ids: [],
				news_source_ids: []
				}
				this.$notify({
					title: 'Nieuwsbericht toegevoegd',
					message: 'Nieuwsbericht is succesvol aangemaakt!',
					type: 'success',
					position: 'bottom-right'
				})
			}).catch((errors) => {
				this.errorMessages = errors;
			});
		},
		updateNewsItem () {
			this.$store.dispatch('newsItem/updateNewsItem', this.editNewsItemModal.newsItem).then(() => {
				this.editNewsItemModal = {
					active: false,
					newsItem: null
				}
				this.$notify({
					title: 'Nieuwsbericht bewerkt',
					message: 'Nieuwsbericht is succesvol bewerkt!',
					type: 'success',
					position: 'bottom-right'
				})
			})
		}
	}
}